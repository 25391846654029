import React from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
// hooks
import useResponsive from '../../hooks/useResponsive';
// config
import { HEADER } from '../../config';

//
import DashboardHeader from './header';

import ComingSoon from '../../pages/ComingSoon';
import NavbarHorizontal from './navbar/NavbarHorizontal';
import { useSelector } from '../../redux/store';
import Image from '../../components/Image';

// ----------------------------------------------------------------------

const MainStyle = styled('main')(({ theme }) => ({
  overflowY: 'auto',
  overflowX: 'visible',
  flexGrow: 1,
  zIndex: 1,
  height: '100%',
  minHeight: '100vh',
  // paddingTop: HEADER.MOBILE_HEIGHT + 5,
  paddingBottom: theme.spacing(1),
  backgroundImage: `url(${require('../../assets/app_bg.png')})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  backgroundAttachment: 'fixed',
  backgroundPosition: 'center',
}));
// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const isDesktop = useResponsive('up', 'lg');

  const { hideMenu } = useSelector((x) => x.pageTitle);

  if (isDesktop) return <ComingSoon />;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <DashboardHeader />

      {/* <Image
        src={require('../../assets/app_bg.png')}
        sx={{ position: 'absolute', left: 0, right: 0, top: 0, bottom: 0, minHeight: '100vw', height: 'auto' }}
      /> */}

      <MainStyle>
        <Outlet />
      </MainStyle>

      {!hideMenu && <NavbarHorizontal />}
    </Box>
  );
};

export default DashboardLayout;
