// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const en = {
  dashboard: 'Dashboard',
  my_loans: 'My Loans',
  notification: 'Notification',
  wait_while_profile_being_verified: 'Kindly wait while your profile is being verified.',
  account: 'Account',
  chat: 'Chat',
  online_bank_account: 'Online Bank Account',
  x_is_required: '{{x}} is required',
  bank: 'Bank',
  account_number: 'Account Number',
  x_must_be_y_to_z_digits_in_length: '{{x}} must be {{y}}-{{z}} digits in length',
  payment: 'Payment',
  total_payment: 'Total Payment',
  wrong_otp: 'Wrong OTP',
  refer_a_friend: 'Refer A Friend',
  apply_loan_details: 'Apply Loan Details',
  my_listing: 'My Listing',
  edit_profile: 'Edit Profile',
  extra_documents: 'Extra Documents',
  employer_name: 'Employer Name',
  employer_contact_number: 'Employer Contact Number',
  upload_payslip_for_last_month: 'Upload payslip for last month',
  upload_bank_statement_salary_last_month: 'Upload bank statement of salary for last month',
  upload_utility_bill_last_month: 'Upload utility bill for last month',
  upload_active_mortgage_statement: 'Upload active mortgage statement',
  dont_worry_your_data_is_kept_protected:
    'Dont worry, your data is kept in accordance to the law and is protected by us!',
  nric_front: 'NRIC Front',
  upload_nric_front: 'Upload NRIC Front',
  nric_back: 'NRIC Back',
  upload_nric_back: 'Upload NRIC Back',
  nric_number: 'NRIC/Passport No.',
  complete_profile_to_submit_loan_application: 'Please complete your profile to submit the loan application.',
  name_as_per_ic: 'Name As Per IC',
  view_example: 'View Example >',
  upload_video_selfie_together_with_nric_x_appname:
    'Upload Video Selfie together with NRIC, Hold the IC and say your name to borrow with {{x}}',
  emergency_contact_1: 'Emergency Contact 1',
  emergency_contact_2: 'Emergency Contact 2',
  relationship: 'Relationship',
  name: 'Name',
  done: 'Done',
  data_is_being_prep: 'Data is being prepared',
  loan_application_failed: 'Loan Application Failed!',
  title_: 'Title: ',
  message_: 'Message: ',
  contact_support: 'Contact Support',
  ok: 'Ok',
  receive_method: 'Receive Method',
  select_bank: 'Select Bank',
  key_in_account_number: 'Key In Account Number',
  congratulations: 'Congratulations!',
  your_loan_has_been_approved: 'Your Loan Has Been\nApproved!',
  payment_amount_xcurrencyx: 'Payment Amount ({{x}})',
  interest: 'Interest',
  underwriting_fee: 'Underwriting Fee',
  gateway_fee: 'Gateway Fee',
  service_fee: 'Service Fee',
  deposit: 'Deposit',
  total_amount_received: 'Total amount received',
  apply_now: 'Apply Now',
  application_submitted_wait_pic_to_serve_u:
    'Your application is successfully submitted. Please wait for our person-in-charge to serve you.',
  mobile_number: 'Mobile Number',
  _due_: '  Due: ',
  pay_now: 'Pay Now',
  recent_transactions: 'Recent Transactions',
  no_loan_yet: 'No Loan(s) yet',
  no_notification: 'No Notifications...',
  enter_any_payment_amount: 'Enter any payment amount',
  select_payment_method: 'Select Payment Method',
  invite_your_friend_to_x_app: 'Invite your friend to {{x}} app!',
  about_x: 'About {{x}}',
  upload_receipt_x_will_verify_payment:
    'Please upload a picture of ATM / cash deposit receipt, {{x}} will verify your payment within 3 working days.',
  pay_to_either_one_of_x_accounts: "Use ATM or Cash deposit to pay to either one of the following {{x}}'s accounts:",
  youre_one_step_away_x_click_next_to_proceed:
    'You are now one step away from enjoying the benefits of {{x}}. Click Next to proceed.',
  welcome_to_x: 'Welcome To {{x}}',
  share_now: 'Share Now',
  copy: 'Copy',
  your_referral_history: 'Your Referral History',
  share_your_referral_code: 'Share Your Referral Code',
  how_referral_works: 'How Referral Works',
  copied: 'Copied',
  how_referral_works_x_y_commission:
    'Our commission formula allows referrals to gain {{x}} and {{y}} from the first loan and subsequent loans of their invitees respectively. These commissions will be credited to referrals when their invitees have completed full repayment.',
  customer_support: 'Customer Support',
  date: 'Date',
  amount: 'Amount',
  status: 'Status',
  enter_payment_amount: 'Enter payment amount',
  entered_amount_more_than_you_owe: 'Entered amount is more than what you owe.',
  oops_we_are_sorry: 'Oops, We Are Sorry',
  more_time_needed_will_inform_once_completed: "More time is needed, we will inform you once it's completed.",
  contact_us: 'Contact Us',
  proceed_to_online_banking_to_pay_loan: 'Proceed to online banking to pay loan',
  proceed: 'Proceed',
  thank_you_for_payment: 'Thank you for your payment!',
  please_register_an_account: 'Please register an account',
  referral_code_optional: 'Referral Code (Optional)',
  register: 'Register',
  downline_name: 'Downline Name',
  no_: 'No.',
  total_commission: 'Total Commission',
  total_withdrawal: 'Total Withdrawal',
  balance: 'Balance',
  total_register_: 'Total Register: ',
  total_loans_: 'Total Loans: ',
  upload_your_receipt: 'Upload your receipt',
  email_address: 'Email Address',
  password: 'Password',
  forget_password: 'Forgot Password',
  sign_in: 'Sign In',
  upload_photo: 'Upload Photo',
  title: 'Title',
  edit: 'Edit',
  delete: 'Delete',
  view: 'View',
  no: 'No',
  yes: 'Yes',
  phone_number: 'Phone Number',
  save: 'Save',
  forgot_password: 'Forgot Password',
  submit: 'Submit',
  please_verify_account_first: 'Please Verify Account First',
  are_you_ready_to_nbe_a_part_of_the_family: 'Are you ready to\nbe a part of the family.',
  full_name: 'Full Name',
  verify_account: 'Verify Account',
  skip: 'Skip',
  next: 'Next',
  let_s_get_started: "Let's get Started",
  please_enter_otp: 'Please Enter OTP sent to your number',
  new_password: 'New Password',
  please_enter_your_nnew_password_to_reset_your_password: 'Please enter your\nnew password to reset your password',
  confirm_password: 'Confirm Password',
  reset_password: 'Reset Password',
  welcome_back_yo_ve_nbeen_missed: "Welcome back you've\nbeen missed!",
  let_s_sign_you_in: "Let's Sign you in.",
  choose_your_language: 'Select Your language to begin!',
  login_now: 'Login now.',
  sign_up: 'Sign Up',
  log_in: 'Log In',
  empty_field_found: 'Empty Field Found',
  invalid_x: 'Invalid {{x}}',
  invalid_nric_please_enter_x_format: 'Invalid NRIC Format. Please enter {{x}}',
  something_went_wrong_x: 'Something went wrong: {{x}}',
  something_went_wrong_pls_contact_support: 'Something went wrong. Please contact support.',
  enter_valid_mobile_no: 'Enter Valid Mobile No.',
  record_already_exists: 'Record already exists',
  x_already_exists: '{{x}} already exists!',
  permission_turned_off_can_be_enabled_in_app_settings:
    'It looks like you have turned off permissions required for this feature. It can be enabled under App settings!',
  go_to_settings: 'Go To Settings',
  cancel: 'Cancel',
  payment_info: 'Payment Information',
  no_amount_and_id_found: 'No amount and ID found',
  name_of_correspondence: 'Name of correspondence',
  number_of_correspondence: 'Number of correspondence',
  emergency_contact_1_2_x_shouldnt_be_the_same: 'Emergency Contact 1 and 2 {{x}} should not be the same',
  referral_history: 'Referral History',
  withdrawal_history: 'Withdrawal History',
  loan_packages: 'Loan Packages',
  ensure_settle_payment_b4_due_date_to_avoid_penalty:
    'Ensure that you settle your payment before its due date to avoid payment penalty.',
  period: 'Period',
  identity_nverification: 'Identity\n Verification',
  threemin_nreview: '3-minute\n Review',
  threemin_nreceival: '3-minute\n Receival',
  identity: 'Identity',
  verification: 'Verification',
  threemin: '3-minute',
  review: 'Review',
  receival: 'Receival',
  nric_name: 'NRIC Name',
  _days: ' Days',
  details: 'Details',
  announcements: 'Announcements',
  no_internet_connection: 'No Internet Connection',
  retry: 'Retry',
  code_sent: 'Code Sent',
  otp_verification_failed: 'OTP Verification Failed',
  invalid_credential: 'Invalid Credential',
  firebase_quota_exceeded: 'Quota Exceeded',
  wrong_otp_code: 'Wrong OTP Code',
  too_many_request_detected: 'Too many request detected, please try again later',
  account_submitted: 'Account information Submitted',
  loan_application_submitted: 'Loan Application Submitted',
  please_wait_our_admin_to_contact_you_soon: 'Please wait for our admin to contact you soon.',
  whats_app_no: 'WhatsApp Number',
  invite_message_x_referral_code_x: 'Hey I found a Zero Interest loan app. {{x}} My Referral Code is {{y}}',
  nric_format_name: 'MyKad Number without dash',
  nric_min_length_x: 'Minimum length is {{x}}',
  select_country_region: 'Select a Country / Region',
  request_contact_list_permission_reason:
    'We require access to your contact list to help detect and prevent fraud. By comparing your contacts against our database, we can identify any suspicious activity and help keep your account and personal information secure.',
  permission_request: 'Permission Request',
  app_wont_work_without_permission: 'The app will not work without the permission(s): ',
  go_to_app_settings_allow_permission_manually: 'Please go to app settings and allow them manually',
  allow: 'Allow',
  take_photo_using_camera: 'Take Photo Using Camera',
  select_photo_from_gallery: 'Select Photo from Gallery',
  select_pdf_file: 'Select PDF File',
  failed_to_attach_photo: 'Failed To Attach Photo',
  signup_agree_privacy_policy: 'By signing up, you agree to our Privacy Policy.',
  login_agree_privacy_policy: 'By signing in, you agree to our Privacy Policy.',
  request_upload_photo_permission_reason:
    'We require your permission to upload photos from your device to our server securely for KYC purpose. We will not share your photos with any third parties without your explicit consent.',
  upload_failed_please_try_again: 'Upload Failed. Please Try Again',
  loan_payment_failed: 'Loan Payment Failed',
  complete_kyc_note: `To make your loan approval process quicker and easier, please complete your KYC (Know Your Customer) details. This helps us serve you better!`,
};

export default en;
