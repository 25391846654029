import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Stack, AppBar, Toolbar, IconButton, Typography, SvgIcon } from '@mui/material';
// hooks
// import useOffSetTop from '../../../hooks/useOffSetTop';
// import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { useSelector } from '../../../redux/store';
import conObj from '../../../utils/connection-assistant';
import Image from '../../../components/Image';
import SvgIconStyle from '../../../components/SvgIconStyle';
import palette from '../../../theme/palette';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  ...cssStyles(theme).bgBlur({ opacity: 0.1 }),
  boxShadow: 'none',
  paddingLeft: 10,
  paddingRight: 10,
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {};

function buildWhatsappSupportUrl(phoneNum) {
  if (!phoneNum) return null;

  return `https://api.whatsapp.com/send?phone=${phoneNum}`;
}

export default function DashboardHeader() {
  const { title, isSubPage } = useSelector((x) => x.pageTitle);

  const [liveSupportWhatsappPhoneNumber, setLiveSupportWhatsappPhoneNumber] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getSettings();
    console.log(`title = ${title}`);
  }, []);

  const getSettings = () => {
    conObj.get('Setting').then((res) => {
      if (res.status === 200) {
        // fPayPaymentGatewayEnabled,
        // implementationType,
        // liveSupportWhatsappPhoneNumber,
        // manualBankingEnabled,
        // referralCommissionFirstLoan,
        // referralCommissionSubsequentLoan,
        // surePay88PaymentGatewayEnabled,
        // vPay88PaymentGatewayEnabled,
        sessionStorage.setItem('appSettings', JSON.stringify(res.data));
        setLiveSupportWhatsappPhoneNumber(res.data.liveSupportWhatsappPhoneNumber);
      }
    });
  };

  return (
    <RootStyle>
      <Toolbar sx={{ px: 0 }}>
        {isSubPage && (
          <IconButton onClick={() => navigate(-1)} color="primary" sx={{ mr: 2 }}>
            <Iconify icon="mdi:arrow-left" sx={{ color: '#FFBC51' }} />
          </IconButton>
        )}
        {title === 'Dashboard' ? (
          <Image
            src={require('../../../assets/app_logo.png')}
            sx={{
              height: '40px',
              width: '50px',
              img: {
                objectFit: 'contain',
              },
            }}
          />
        ) : (
          <Typography variant="h4" sx={{ color: '#fff' }}>
            {title || ''}
          </Typography>
        )}

        <Box sx={{ flexGrow: 1 }} />
        {liveSupportWhatsappPhoneNumber && (
          <Stack
            direction="row"
            alignItems="center"
            spacing={{ xs: 0.5, sm: 1.5 }}
            sx={{ color: `${palette.textColors.primary} !important` }}
          >
            <a
              href={liveSupportWhatsappPhoneNumber ? buildWhatsappSupportUrl(liveSupportWhatsappPhoneNumber) : {}}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}
            >
              <Iconify icon={'hugeicons:customer-support'} sx={{ width: 25, height: 25, color: '#FFBC51' }} />
            </a>
          </Stack>
        )}
      </Toolbar>
    </RootStyle>
  );
}
